<script setup lang="ts">
import dayjs from "dayjs";

import type { Promotion } from "@/types";

const { t } = useT();
const { open } = useTaoModals();

const props = defineProps<{
	type?: Promotion["type"];
	image: string;
	title: string;
	entries: number;
	end: string;
	isAvailable: boolean;
}>();

const timeRemaining = dayjs(props.end).diff(dayjs(), "d") > 1 ? "D[ d] HH[ h] mm[ m]" : "HH[ h] mm[ m] ss[ s]";

const { durationLeft, durationExceeded } = useCountdown({
	timestamp: props.end || "",
	formatToken: timeRemaining
});

const handleHowItWorksModal = () => {
	open("LazyOModalSeasonHowItWorks");
};

const handleSeasonModal = () => {
	open("LazyOModalSeasonTower");
};
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 150,
			height: 150,
			src: image,
			alt: 'promo-season',
			format: 'avif',
			loading: 'lazy'
		}"
		width="330px"
		height="310px"
		class="card-season"
	>
		<template #default>
			<span class="btn-info" @click.stop="handleHowItWorksModal">
				<ASvg name="16/info" class="icon" />
			</span>
		</template>
		<template #top>
			<AText v-if="!durationExceeded" :size="12" class="label-time">
				{{ t("Time left") }} <b>{{ durationLeft }}</b>
			</AText>
			<AText class="title" :modifiers="['bold', 'uppercase']">
				{{ title }}
			</AText>
		</template>
		<template #bottom>
			<div class="box">
				<AText v-if="entries" class="info-txt" as="div" :size="10" :modifiers="['center', 'uppercase']">
					{{ t("Climb up treasure tower and collect") }}
				</AText>
				<AText v-if="entries" :size="28" :modifiers="['bold', 'uppercase']" class="count">
					<ASvg name="12/secret-coins" class="entries" />
					{{ numberFormat(entries) }}
				</AText>
				<AButton v-if="!isAvailable" variant="primary" class="promo" data-tid="promo-season">
					<AText :modifiers="['bold', 'uppercase']">
						{{ t("Unlock Tower") }}
					</AText>
					<NuxtIcon name="22/ticket" class="icon-ticket" filled />
				</AButton>
				<AButton v-else variant="primary" class="promo" data-tid="promo-season">
					<AText :modifiers="['bold', 'uppercase']">
						{{ t("Play to climb") }}
					</AText>
				</AButton>
				<AText
					as="div"
					type="x-small"
					:modifiers="['uppercase', 'link-underline', 'bold']"
					class="foot color-neutral"
					@click.stop="handleSeasonModal"
				>
					{{ t("Find out more") }}
				</AText>
			</div>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card-season.default {
	:deep(> img) {
		width: 150px;
		height: 150px;
		left: calc(50% - 72px);
	}
}
.card {
	color: var(--neutral-50);
	background: var(--gradient-9);
}

.box {
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 100%;
	gap: 8px;

	button {
		width: 100%;
		height: 40px;
	}
}

.info-txt {
	margin: 0 auto;
}

.label-time {
	display: inline-flex;
	gap: 4px;
	border-radius: 4px;
	padding: 3px 4px;
	background: var(--overlay-100);
}

.count {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;

	.entries {
		transform: translateY(-1px);
		font-size: 24px;
	}
}

.icon-ticket {
	font-size: 24px;
	margin-left: 8px;
}

.btn-info {
	position: absolute;
	z-index: 2;
	cursor: pointer;
	top: 10px;
	left: 10px;

	.icon {
		font-size: 16px;
	}
}
</style>
